import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { getAll, getByCategory } from "../../API/Api";
import PageHeader from "../../modules/pageHeader/PageHeader";
import BlogColumn from "./BlogColumn";
import Select from "react-select";

function Blogs() {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [totalBlogs, setTotalBlogs] = useState(6);
  const [loader, setLoader] = useState(false);
  const [category, setCategory] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });

    setTimeout(() => {
      setVisible("visible");
    }, 500);

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {
    setLoader(true);
    getAll("Blogs")
      .then((res) => {
        setBlogs([...res?.data]);
        setLoader(false);
        const temp = [];
        res?.data?.forEach((val) => {
          if (!temp?.some((cat) => cat?.value === val?.category)) {
            temp.push({ value: val?.category, label: val?.category });
          }
        });
        setCategory([...temp]);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  const filerCategory = (filterData) => {
    setLoader(true);

    if (!filterData?.length) {
      getAll("Blogs")
        .then((res) => {
          setBlogs([...res?.data]);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      getByCategory("Blogs", filterData)
        .then((res) => {
          setBlogs([...res?.data]);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  
  useEffect(() => {}, [scrollTop]);

  return (
    <>
      {/*<!-- start of page-header -->*/}
      <PageHeader
        heading={
          <h2
            className={`text-white font-weight-500 has-anim ${visible} fade anim-delay-1`}
          >
            Blog
            <span className="text-primary">.</span>
          </h2>
        }
        text={"Latest news"}
      />

      {/*<!-- end of page-header -->*/}

      {/*<!-- start of 3 column blogs  -->*/}
      <div className="section pt-n30 bg-white">
        <div className="container">
          <div className="row">
            <div className="filter-select">
              <Select
                className="filter-box"
                isMulti
                defaultValue={null}
                placeholder={"Category..."}
                name="filter"
                options={category}
                onChange={(e) => filerCategory(e.map((val) => val?.value))}
              />
            </div>

            <div className="input-group col-md-3">
              <input
                className="form-control py-2 border-right-0 border"
                type="search"
                id="example-search-input"
                placeholder="Search"
                onChange={(e) => setFilter(e?.target?.value?.toLowerCase())}
              />
              <span className="input-group-append">
                <div className="input-group-text bg-transparent">
                  <i className="bi bi-search"></i>
                </div>
              </span>
            </div>

            <div className="spinner">
              {!!loader && (
                <TailSpin
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              )}
            </div>
            {!loader &&
              blogs
                ?.filter(
                  (val) =>
                    val?.title?.toLowerCase()?.includes(filter) ||
                    val?.details?.toLowerCase()?.includes(filter)
                )
                ?.map(
                  (blog, index) =>
                    index < totalBlogs && (
                      <BlogColumn key={index} visible={visible} blog={blog} />
                    )
                )}
            <div className="col-lg-12">
              <div className={`text-center mt-5 pt-4 has-anim ${visible}`}>
                {!loader && blogs?.length > totalBlogs && (
                  <button
                    className="btn btn-black"
                    data-text="Older Posts"
                    onClick={() => {
                      if (blogs?.length > totalBlogs)
                        setTotalBlogs((prev) => prev + 6);
                    }}
                  >
                    <span>Older Posts</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<!-- end of 3 column blogs  -->*/}
    </>
  );
}

export default Blogs;
