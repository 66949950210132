import React from "react";
import styles from "./Template4.module.scss";
import pic from "../../assets/images/Book And Magazine/Minimalist Banner.png";

function Template4({ content }) {
  return (
    <div
      className={`container-fluid position-relative ${styles.body} px-0 d-flex align-items-center flex-column`}
    >
      <img className="w-100" src={pic} alt="pic" />
      <div
        className={`${styles.box} w-100 h-100 text-center position-absolute d-flex flex-column justify-content-center align-items-center`}
      >
        <h3>{content?.split("|")[0]}</h3>
        <p>{content?.split("|")[1]}</p>
      </div>
    </div>
  );
}

export default Template4;
