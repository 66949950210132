import { BrowserRouter, Routes, Route } from "react-router-dom";
import DigitalAgency from "../components/home/DigitalAgency";
import Portfolio from "../components/portfolio/Portfolio";
import PortfolioSingle from "../components/portfolio/PortfolioSingle";
import Blogs from "../components/blogs/Blogs";
import BlogSingle from "../components/blogs/BlogSingle";
import Contact from "../components/about/Contact";
import Team from "../components/about/Team";
import Services from "../components/services/Services";
import Body from "../Body";
import BookAndMagazine from "../components/services/Book And Magazine/BookAndMagazine";
import ClothingAndMerchandise from "../components/services/Clothing and Merchandise/ClothingAndMerchandise";
import ApparelDesigning from "../components/services/Clothing and Merchandise/ApparelDesigning";
import BagTote from "../components/services/Clothing and Merchandise/BagTote";
import JerseysHoodies from "../components/services/Clothing and Merchandise/JerseysHoodies";
import ShoppingBag from "../components/services/Clothing and Merchandise/ShoppingBag";
import PackagingLabel from "../components/services/Packaging and Label/PackagingLabel";
import Cosmetics from "../components/services/Packaging and Label/Cosmetics";
import FoodPackaging from "../components/services/Packaging and Label/FoodPackaging";
import ProductPackaging from "../components/services/Packaging and Label/ProductPackaging";
import RetailPackaging from "../components/services/Packaging and Label/RetailPackaging";
import PromotionalProducts from "../components/services/Promotional Products/PromotionalProducts";
import SocialMedia from "../components/services/Social Media Design/SocialMedia";
import WebApp from "../components/services/Web App Design/WebApp";
import AppDesign from "../components/services/Web App Design/AppDesign";
import Tshirt from "../components/services/Clothing and Merchandise/Tshirt";
import BrandIdentity from "../components/services/Logo Identity/BrandIdentity";
import LogoDesigning from "../components/services/Logo Identity/LogoDesigning";
import LogoIdentity from "../components/services/Logo Identity/LogoIdentity";
import Landing from "../components/services/Web App Design/Landing";
import Website from "../components/services/Web App Design/Website";
import BookCover from "../components/services/Book And Magazine/BookCover";
import EbookCover from "../components/services/Book And Magazine/EbookCover";
import MagazineDesigning from "../components/services/Book And Magazine/MagazineDesigning";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route paht="/" element={<Body />}>
          <Route index element={<DigitalAgency />}></Route>
          <Route path="portfolio" element={<Portfolio />}></Route>
          <Route
            path="/portfolio/single-portfolio"
            element={<PortfolioSingle />}
          ></Route>
          <Route path="blog" element={<Blogs />}></Route>
          <Route path="/blog/:id" element={<BlogSingle />}></Route>
          <Route path="contact" element={<Contact />}></Route>
          <Route path="team" element={<Team />}></Route>
          <Route path="services" element={<Services />}></Route>

          <Route path="book-magazine" element={<BookAndMagazine />}></Route>
          <Route
            path="book-magazine/book-cover"
            element={<BookCover />}
          ></Route>
          <Route
            path="book-magazine/ebook-cover"
            element={<EbookCover />}
          ></Route>
          <Route
            path="book-magazine/magazine-designing"
            element={<MagazineDesigning />}
          ></Route>

          <Route
            path="clothing-merchandise"
            element={<ClothingAndMerchandise />}
          ></Route>
          <Route
            path="clothing-merchandise/apparel"
            element={<ApparelDesigning />}
          ></Route>
          <Route
            path="clothing-merchandise/bag-tote"
            element={<BagTote />}
          ></Route>
          <Route
            path="clothing-merchandise/jerseys-hoodies"
            element={<JerseysHoodies />}
          ></Route>
          <Route
            path="clothing-merchandise/shopping-bag"
            element={<ShoppingBag />}
          ></Route>
          <Route
            path="clothing-merchandise/tshirt"
            element={<Tshirt />}
          ></Route>

          <Route path="logo-identity" element={<LogoIdentity />}></Route>
          <Route path="logo-identity/brand" element={<BrandIdentity />}></Route>
          <Route path="logo-identity/logo" element={<LogoDesigning />}></Route>

          <Route path="packaging-label" element={<PackagingLabel />}></Route>
          <Route
            path="packaging-label/cosmetics"
            element={<Cosmetics />}
          ></Route>
          <Route
            path="packaging-label/food-packaging"
            element={<FoodPackaging />}
          ></Route>
          <Route
            path="packaging-label/product-packaging"
            element={<ProductPackaging />}
          ></Route>
          <Route
            path="packaging-label/retail-packaging"
            element={<RetailPackaging />}
          ></Route>

          <Route
            path="promotional-products"
            element={<PromotionalProducts />}
          ></Route>

          <Route path="social-media" element={<SocialMedia />}></Route>

          <Route path="web-app" element={<WebApp />}></Route>
          <Route path="web-app/landing" element={<Landing />}></Route>
          <Route path="web-app/app-design" element={<AppDesign />}></Route>
          <Route path="web-app/website" element={<Website />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
