import React from "react";
import { TailSpin } from "react-loader-spinner";
import BlogColumn from "./BlogColumn";

function FooterBlogs({ title, Blogs, visible, loader }) {
  return (
    <>
      <div className="section pt-0 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="section border-top pb-0"></div>
              <h3
                className={`h2 text-dark font-weight-500 has-anim ${visible} fade`}
              >
                {title}
              </h3>
            </div>
            <div className="spinner">
              {!!loader && (
                <TailSpin
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              )}
            </div>
            {!loader &&
              Blogs?.map((val, index) => (
                <BlogColumn key={index} visible={visible} blog={val} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterBlogs;
