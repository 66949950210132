import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageHeader from "../../modules/pageHeader/PageHeader";

function Contact() {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setVisible("visible");
    }, 500);

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {}, [scrollTop]);
  return (
    <>
      {/*<!-- start of page-header -->*/}
      <PageHeader
        text={"Contact Us"}
        heading={
          <h2
            className={`text-white font-weight-500 has-anim ${visible} fade anim-delay-1`}
          >
            We’d love to hear from you, <br></br> get in touch with us 👋
          </h2>
        }
      />
      {/*<!-- end of page-header -->*/}

      {/*<!-- start of contact us -->*/}
      <section className="section contact-us bg-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-11">
              <div className="block">
                <div className="row justify-content-center">
                  <div className="col-lg-5 mb-5 mb-lg-0">
                    <div className="pr-0 pr-lg-4">
                      <h4 className={`text-dark mb-3 has-anim ${visible} fade`}>
                        Describe Your Project
                      </h4>
                      <p className={`has-anim ${visible} fade`}>
                        Leave us your contact info, we’ll get back to you within
                        24 hours. Stay up to date with our newest collections,
                        latest deals and special offers!
                      </p>

                      <div
                        className={`mt-4 pt-2 has-anim ${visible} fade anim-delay-1`}
                      >
                        <p className="text-uppercase small font-weight-500 mb-1">
                          Email us:
                        </p>
                        <p>
                          <Link className="line-link text-dark">
                            contact@abildon.io
                          </Link>
                        </p>
                      </div>
                      <div
                        className={`mt-4 has-anim ${visible} fade anim-delay-2`}
                      >
                        <p className="text-uppercase small font-weight-500 mb-1">
                          Call us:
                        </p>
                        <p>
                          <Link className="line-link text-dark">
                            +072 223 2424
                          </Link>
                        </p>
                      </div>
                      <div
                        className={`mt-4 has-anim ${visible} fade anim-delay-3`}
                      >
                        <p className="text-uppercase small font-weight-500 mb-1">
                          Address:
                        </p>
                        <p>Qvid city, 02000</p>
                        <p>16 Nikola Y. Vaptsarov Blvd,</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <form action="#">
                      <div className="row gh-1 gv-2">
                        <div
                          className={`col-12 col-md-6 has-anim ${visible} fade`}
                        >
                          <input
                            type="name"
                            className="form-control"
                            placeholder="Your Name *"
                          />
                        </div>
                        <div
                          className={`col-12 col-md-6 has-anim ${visible} fade`}
                        >
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Your Email *"
                          />
                        </div>
                        <div
                          className={`col-12 col-md-6 has-anim ${visible} fade`}
                        >
                          <input
                            type="phone"
                            className="form-control"
                            placeholder="Your Phone *"
                          />
                        </div>
                        <div
                          className={`col-12 col-md-6 has-anim ${visible} fade`}
                        >
                          <input
                            type="company"
                            className="form-control"
                            placeholder="Company"
                          />
                        </div>
                        <div
                          className={`col-12 col-md-6 has-anim ${visible} fade`}
                        >
                          <select className="form-control custom-control custom-select">
                            <option>Type of Project</option>
                            <option>Web/App UI Project</option>
                            <option>Creative Branding</option>
                            <option>Wordpress Website</option>
                            <option>Static Website</option>
                          </select>
                        </div>
                        <div
                          className={`col-12 col-md-6 has-anim ${visible} fade`}
                        >
                          <select className="form-control custom-control custom-select">
                            <option>Your Budget</option>
                            <option>&lt; 10,000</option>
                            <option>10k-20k</option>
                            <option>20k-30k</option>
                            <option>&gt; 30,000</option>
                          </select>
                        </div>
                        <div className={`col-12 has-anim ${visible} fade`}>
                          <textarea
                            className="form-control"
                            rows="1"
                            placeholder="Message *"
                          ></textarea>
                        </div>
                        <div className={`col-12 has-anim ${visible} fade`}>
                          <button
                            className="btn btn-black"
                            type="button"
                            name="button"
                            data-text="Submit"
                          >
                            <span>Submit</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- end of contact us -->*/}
    </>
  );
}

export default Contact;
