import {
  WEBSITE_PAGE_LOADER,
  WEBSITE_PAGE_SUCCESS,
  WEBSITE_PAGE_FAILURE,
  WEBSITE_PAGE_RESET,
} from "../../constants/Web App Design/WebsiteConstants";

const initialState = {
  isLoading: false,
  WebsitePageData: {},
  errorMessage: "",
};

export const WebsiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case WEBSITE_PAGE_LOADER:
      return { ...state, isLoading: true };

    case WEBSITE_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        WebsitePageData: action.payload,
        errorMessage: "",
      };

    case WEBSITE_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case WEBSITE_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
