import React from "react";
import styles from "./introduction.module.scss";

function Introduction({ introduction, callToAction, position }) {
  return (
    <div
      className={`row ${position === "left" ? "flex-row-reverse" : ""} mx-0`}
      style={{ marginBottom: "6vw" }}
    >
      <div
        className={`${styles.body} col-7 d-flex flex-column justify-content-center`}
      >
        <h2>{introduction?.header}</h2>
        <p className={styles.text}>{introduction?.text}</p>
      </div>
      <div
        className={`col-5 d-flex ${
          position === "left" ? "justify-content-start" : "justify-content-end"
        }`}
      >
        <img
          className="w-75"
          src={
            !!introduction?.img
              ? process.env.REACT_APP_CLOUDFLARE_URL + introduction?.img
              : ""
          }
          alt="pic"
        />
      </div>
      <div className="col-12">{callToAction}</div>
    </div>
  );
}

export default Introduction;
