import {
  PACKAGING_LABEL_PAGE_LOADER,
  PACKAGING_LABEL_PAGE_SUCCESS,
  PACKAGING_LABEL_PAGE_FAILURE,
  PACKAGING_LABEL_PAGE_RESET,
} from "../../constants/Packaging and Label/PackagingLabelConstants";

const initialState = {
  isLoading: false,
  PackagingLabelPageData: {},
  errorMessage: "",
};

export const PackagingLabelReducer = (state = initialState, action) => {
  switch (action.type) {
    case PACKAGING_LABEL_PAGE_LOADER:
      return { ...state, isLoading: true };

    case PACKAGING_LABEL_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PackagingLabelPageData: action.payload,
        errorMessage: "",
      };

    case PACKAGING_LABEL_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case PACKAGING_LABEL_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
