import React from "react";
import { Link } from "react-router-dom";

function FooterBottom() {
  const today = new Date();
  return (
    <>
      {/*start of footer-bottom */}
      <section className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mb-5 pb-4">
              <p className="h6 font-weight-light mb-2">
                Copyright &copy; {today.getFullYear() + "."} Developed by{" "}
                <Link className="line-link text-white font-weight-light">
                  Startem
                </Link>
              </p>
              <p className="h6 font-weight-light">
                When you visit or interact with our sites, services or tools, we
                or our authorised service providers may use cookies for storing
                information to help provide you with a better, faster and safer
                experience and for marketing purposes.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*end of footer-bottom */}
    </>
  );
}

export default FooterBottom;
