import {
  JERSEYS_HOODIES_PAGE_LOADER,
  JERSEYS_HOODIES_PAGE_SUCCESS,
  JERSEYS_HOODIES_PAGE_FAILURE,
  JERSEYS_HOODIES_PAGE_RESET,
} from "../../constants/Clothing and Merchandise/JerseysHoodiesConstants";

const initialState = {
  isLoading: false,
  JerseysHoodiesPageData: {},
  errorMessage: "",
};

export const JerseysHoodiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case JERSEYS_HOODIES_PAGE_LOADER:
      return { ...state, isLoading: true };

    case JERSEYS_HOODIES_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        JerseysHoodiesPageData: action.payload,
        errorMessage: "",
      };

    case JERSEYS_HOODIES_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case JERSEYS_HOODIES_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
