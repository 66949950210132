import { combineReducers } from "redux";
import { OurServicesReducer } from "./Services/OurServicesReducer";
import { BookMagazineReducer } from "./Book and Magazine/BookMagazineReducer";
import { ApparelDesigningReducer } from "./Clothing and Merchandise/ApparelDesigningReducer";
import { BagToteReducer } from "./Clothing and Merchandise/BagToteReducer";
import { ClothingMerchandiseReducer } from "./Clothing and Merchandise/ClothingMerchandiseReducer";
import { JerseysHoodiesReducer } from "./Clothing and Merchandise/JerseysHoodiesReducer";
import { ShoppingBagReducer } from "./Clothing and Merchandise/ShoppingBagReducer";
import { TshirtReducer } from "./Clothing and Merchandise/TshirtReducer";
import { BrandIdentityReducer } from "./Logo Identity/BrandIdentityReducer";
import { LogoDesigningReducer } from "./Logo Identity/LogoDesigningReducer";
import { LogoIdentityReducer } from "./Logo Identity/LogoIdentityReducer";
import { CosmeticsReducer } from "./Packaging and Label/CosmeticsReducer";
import { FoodPackagingReducer } from "./Packaging and Label/FoodPackagingReducer";
import { PackagingLabelReducer } from "./Packaging and Label/PackagingLabelReducer";
import { ProductPackagingReducer } from "./Packaging and Label/ProductPackagingReducer";
import { RetailPackagingReducer } from "./Packaging and Label/RetailPackagingReducer";
import { PromotionalProductsReducer } from "./Promotional Products/PromotionalProductsReducer";
import { SocialMediaReducer } from "./Social Media Design/SocialMediaReducer";
import { AppDesignReducer } from "./Web App Design/AppDesignReducer";
import { LandingReducer } from "./Web App Design/LandingReducer";
import { WebAppReducer } from "./Web App Design/WebAppReducer";
import { WebsiteReducer } from "./Web App Design/WebsiteReducer";
import { BookCoverReducer } from "./Book and Magazine/BookCoverReducer";
import { EbookCoverReducer } from "./Book and Magazine/EbookCoverReducer";
import { MagazineDesigningReducer } from "./Book and Magazine/MagazineDesigningReducer";

const rootReducer = combineReducers({
  OurServicesReducer,
  BookMagazineReducer,
  ApparelDesigningReducer,
  BagToteReducer,
  ClothingMerchandiseReducer,
  JerseysHoodiesReducer,
  ShoppingBagReducer,
  TshirtReducer,
  BrandIdentityReducer,
  LogoDesigningReducer,
  LogoIdentityReducer,
  CosmeticsReducer,
  FoodPackagingReducer,
  PackagingLabelReducer,
  ProductPackagingReducer,
  RetailPackagingReducer,
  PromotionalProductsReducer,
  SocialMediaReducer,
  AppDesignReducer,
  LandingReducer,
  WebAppReducer,
  WebsiteReducer,
  BookCoverReducer,
  EbookCoverReducer,
  MagazineDesigningReducer,
});

export default rootReducer;
