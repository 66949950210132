import {
  FOOD_PACKAGING_PAGE_LOADER,
  FOOD_PACKAGING_PAGE_SUCCESS,
  FOOD_PACKAGING_PAGE_FAILURE,
  FOOD_PACKAGING_PAGE_RESET,
} from "../../constants/Packaging and Label/FoodPackagingConstants";

const initialState = {
  isLoading: false,
  FoodPackagingPageData: {},
  errorMessage: "",
};

export const FoodPackagingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FOOD_PACKAGING_PAGE_LOADER:
      return { ...state, isLoading: true };

    case FOOD_PACKAGING_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        FoodPackagingPageData: action.payload,
        errorMessage: "",
      };

    case FOOD_PACKAGING_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case FOOD_PACKAGING_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
