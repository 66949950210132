import {
  BAG_TOTE_PAGE_LOADER,
  BAG_TOTE_PAGE_SUCCESS,
  BAG_TOTE_PAGE_FAILURE,
  BAG_TOTE_PAGE_RESET,
} from "../../constants/Clothing and Merchandise/BagToteConstants";

const initialState = {
  isLoading: false,
  BagTotePageData: {},
  errorMessage: "",
};

export const BagToteReducer = (state = initialState, action) => {
  switch (action.type) {
    case BAG_TOTE_PAGE_LOADER:
      return { ...state, isLoading: true };

    case BAG_TOTE_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        BagTotePageData: action.payload,
        errorMessage: "",
      };

    case BAG_TOTE_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case BAG_TOTE_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
