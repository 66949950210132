import React from "react";
import styles from "./Template3.module.scss";
import pic from "../../assets/images/Book And Magazine/Vector.png";

function Template3({ content }) {
  return (
    <div
      className={`container-fluid position-relative ${styles.body} px-0 d-flex align-items-center flex-column`}
    >
      <img className="w-100" src={pic} alt="pic" />
      <div
        className={`${styles.box} w-100 h-100 text-center position-absolute d-flex justify-content-center align-items-center`}
      >
        <div className={`${styles.innerBox}`}>
          <p>{content?.split("|")[0]}</p>
          <h3>{content?.split("|")[1]}</h3>
        </div>
      </div>
    </div>
  );
}

export default Template3;
