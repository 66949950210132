import {
  WEB_APP_PAGE_LOADER,
  WEB_APP_PAGE_SUCCESS,
  WEB_APP_PAGE_FAILURE,
  WEB_APP_PAGE_RESET,
} from "../../constants/Web App Design/WebAppConstants";

const initialState = {
  isLoading: false,
  WebAppPageData: {},
  errorMessage: "",
};

export const WebAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case WEB_APP_PAGE_LOADER:
      return { ...state, isLoading: true };

    case WEB_APP_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        WebAppPageData: action.payload,
        errorMessage: "",
      };

    case WEB_APP_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case WEB_APP_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
