import {
  BRAND_IDENTITY_PAGE_LOADER,
  BRAND_IDENTITY_PAGE_SUCCESS,
  BRAND_IDENTITY_PAGE_FAILURE,
  BRAND_IDENTITY_PAGE_RESET,
} from "../../constants/Logo Identity/BrandIdentityConstants";

const initialState = {
  isLoading: false,
  BrandIdentityPageData: {},
  errorMessage: "",
};

export const BrandIdentityReducer = (state = initialState, action) => {
  switch (action.type) {
    case BRAND_IDENTITY_PAGE_LOADER:
      return { ...state, isLoading: true };

    case BRAND_IDENTITY_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        BrandIdentityPageData: action.payload,
        errorMessage: "",
      };

    case BRAND_IDENTITY_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case BRAND_IDENTITY_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
