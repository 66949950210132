import React, { useEffect, useState } from "react";
import PageHeader from "../../modules/pageHeader/PageHeader";

function Services() {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setVisible("visible");
    }, 500);

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {}, [scrollTop]);
  return (
    <>
      {/*<!-- start of page-header -->*/}
      <PageHeader
        heading={
          <h2
            className={`text-white font-weight-500 has-anim ${visible} fade anim-delay-1`}
          >
            We build creative experiences
            <span className="text-primary">.</span>
          </h2>
        }
        text={"What we do"}
      />

      {/*<!-- end of page-header -->*/}

      {/*<!-- start of services  -->*/}
      <section className="section bg-white">
        <div className="container">
          <div
            className={`row justify-content-center align-items-center has-anim ${visible} fade`}
          >
            <div className="col-lg-6 col-md-10 mb-4 mb-lg-0 pb-2 pb-lg-0">
              <div className="pr-0 pr-lg-3">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={require("../../assets/images/services/design.jpg")}
                  alt="service_image"
                />
              </div>
            </div>
            <div className="col-lg-5 col-md-10 position-relative">
              <div className="service-icon-lg">
                <svg
                  viewBox="0 0 16 16"
                  fill="#8bc34a"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 15V1a7 7 0 1 1 0 14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"
                  />
                </svg>
              </div>

              <h3 className="text-dark font-weight-600 mb-4">
                Design &amp; Branding
              </h3>
              <p className="mt-2">
                We build simple, beautiful and differentiated digital products
                what users want. Cupiditate laborum facilis repellat explicabo a
                temporib.
              </p>
              <p className="mt-2">
                Reprehenderit temporibus doloremque cupiditate rem magnam vitae,
                est qui!
              </p>

              <ul className="service-info-list text-dark mt-4">
                <li>Brand Positioning</li>
                <li>Naming</li>
                <li>Logo Design</li>
                <li>Motion Design</li>
                <li>Identity Systems</li>
                <li>Art Direction</li>
                <li>And more...</li>
              </ul>

              <span
                className="my-2 d-inline-block"
                style={{ letterSpacing: "-3px" }}
              >
                ━━
              </span>
              <p>145 projects done</p>
            </div>
          </div>

          <div
            className={`row justify-content-center align-items-center mt-5 pt-5 has-anim ${visible} fade`}
          >
            <div className="col-lg-5 col-md-10 position-relative">
              <div className="service-icon-lg">
                <svg
                  viewBox="0 0 16 16"
                  fill="#ecb612"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 6a6 6 0 1 1 12 0A6 6 0 0 1 0 6z" />
                  <path d="M12.93 5h1.57a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1.57a6.953 6.953 0 0 1-1-.22v1.79A1.5 1.5 0 0 0 5.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 4h-1.79c.097.324.17.658.22 1z" />
                </svg>
              </div>

              <h3 className="text-dark font-weight-600 mb-4">Marketing</h3>
              <p className="mt-2">
                Increase your sales thanks to our services tailor-made to your
                client’s need. Cupiditate laborum facilis repellat explicabo a
                temporib.
              </p>
              <p className="mt-2">
                Reprehenderit temporibus doloremque cupiditate rem magnam vitae,
                est qui!
              </p>

              <ul className="service-info-list text-dark mt-4">
                <li>Brand Positioning</li>
                <li>Naming</li>
                <li>Logo Design</li>
                <li>Motion Design</li>
                <li>Identity Systems</li>
                <li>Art Direction</li>
                <li>And more...</li>
              </ul>

              <span
                className="my-2 d-inline-block"
                style={{ letterSpacing: "-3px" }}
              >
                ━━
              </span>
              <p>89 projects done</p>
            </div>
            <div className="col-lg-6 col-md-10 mb-4 mb-lg-0 pb-2 pb-lg-0">
              <div className="pr-0 pr-lg-3">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={require("../../assets/images/services/marketing.jpg")}
                  alt="service_image"
                />
              </div>
            </div>
          </div>

          <div
            className={`row justify-content-center align-items-center mt-5 pt-5 has-anim ${visible} fade`}
          >
            <div className="col-lg-6 col-md-10 mb-4 mb-lg-0 pb-2 pb-lg-0">
              <div className="pr-0 pr-lg-3">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={require("../../assets/images/services/webapp.jpg")}
                  alt="service_image"
                />
              </div>
            </div>
            <div className="col-lg-5 col-md-10 position-relative">
              <div className="service-icon-lg">
                <svg
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 .989c.127 0 .253.049.35.145l6.516 6.516a.495.495 0 0 1 0 .7L8.35 14.866a.493.493 0 0 1-.35.145V.989z"
                  />
                </svg>
              </div>

              <h3 className="text-dark font-weight-600 mb-4">
                Web Application
              </h3>
              <p className="mt-2">
                Choose proven technologies, ensuring unlimited possibilities for
                your site. Cupiditate laborum facilis repellat explicabo a
                temporib.
              </p>
              <p className="mt-2">
                Reprehenderit temporibus doloremque cupiditate rem magnam vitae,
                est qui!
              </p>

              <ul className="service-info-list text-dark mt-4">
                <li>Brand Positioning</li>
                <li>Naming</li>
                <li>Logo Design</li>
                <li>Motion Design</li>
                <li>Identity Systems</li>
                <li>Art Direction</li>
                <li>And more...</li>
              </ul>

              <span
                className="my-2 d-inline-block"
                style={{ letterSpacing: "-3px" }}
              >
                ━━
              </span>
              <p>200+ projects done</p>
            </div>
          </div>

          <div
            className={`row justify-content-center align-items-center mt-5 pt-5 has-anim ${visible} fade`}
          >
            <div className="col-lg-5 col-md-10 position-relative">
              <div className="service-icon-lg">
                <svg
                  viewBox="0 0 16 16"
                  fill="#40c39f"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm5 10v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2v5a2 2 0 0 1-2 2H5z"
                  />
                </svg>
              </div>

              <h3 className="text-dark font-weight-600 mb-4">
                Interactive Design
              </h3>
              <p className="mt-2">
                Dolores fuga saepe sunt cumque perferendis repudian daetab culpa
                ratione. Cupiditate laborum facilis repellat explicabo a
                temporib.
              </p>
              <p className="mt-2">
                Reprehenderit temporibus doloremque cupiditate rem magnam vitae,
                est qui!
              </p>

              <ul className="service-info-list text-dark mt-4">
                <li>Brand Positioning</li>
                <li>Naming</li>
                <li>Logo Design</li>
                <li>Motion Design</li>
                <li>Identity Systems</li>
                <li>Art Direction</li>
                <li>And more...</li>
              </ul>

              <span
                className="my-2 d-inline-block"
                style={{ letterSpacing: "-3px" }}
              >
                ━━
              </span>
              <p>93 projects done</p>
            </div>
            <div className="col-lg-6 col-md-10 mb-4 mb-lg-0 pb-2 pb-lg-0">
              <div className="pr-0 pr-lg-3">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={require("../../assets/images/services/interactive-design.jpg")}
                  alt="service_image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- end of services  -->*/}
    </>
  );
}

export default Services;
