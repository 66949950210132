import {
  PACKAGING_LABEL_PAGE_LOADER,
  PACKAGING_LABEL_PAGE_SUCCESS,
  PACKAGING_LABEL_PAGE_FAILURE,
} from "../../constants/Packaging and Label/PackagingLabelConstants";
import { getPage } from "../../../API/Api";

const success = (payload) => ({
  type: PACKAGING_LABEL_PAGE_SUCCESS,
  payload,
});

const failure = (error) => ({
  type: PACKAGING_LABEL_PAGE_FAILURE,
  error,
});

export const PackagingLabelData = () => (dispatch) => {
  dispatch({ type: PACKAGING_LABEL_PAGE_LOADER });
  getPage("designem_page", {
    filter: {
      page_name: {
        _eq: "Packaging and Labels",
      },
    },
    fields: "*.*.*.*.*.*.*",
  })
    .then((response) => {
      if (!!response?.data?.length) dispatch(success(response?.data[0]));
      else dispatch(failure("Something Went Wrong"));
    })
    .catch((err) => {
      dispatch(failure(err?.errors));
    });
};
