import {
  BOOK_COVER_PAGE_LOADER,
  BOOK_COVER_PAGE_SUCCESS,
  BOOK_COVER_PAGE_FAILURE,
} from "../../constants/Book and Magazine/BookCoverConstants";
import { getPage } from "../../../API/Api";

const success = (payload) => ({
  type: BOOK_COVER_PAGE_SUCCESS,
  payload,
});

const failure = (error) => ({
  type: BOOK_COVER_PAGE_FAILURE,
  error,
});

export const BookCoverData = () => (dispatch) => {
  dispatch({ type: BOOK_COVER_PAGE_LOADER });
  getPage("designem_page", {
    filter: {
      page_name: {
        _eq: "Book Cover",
      },
    },
    fields: "*.*.*.*.*.*.*",
  })
    .then((response) => {
      if (!!response?.data?.length) dispatch(success(response?.data[0]));
      else dispatch(failure("Something Went Wrong"));
    })
    .catch((err) => {
      dispatch(failure(err?.errors));
    });
};
