import {
  BOOK_MAGAZINE_PAGE_LOADER,
  BOOK_MAGAZINE_PAGE_SUCCESS,
  BOOK_MAGAZINE_PAGE_FAILURE,
  BOOK_MAGAZINE_PAGE_RESET,
} from "../../constants/Book and Magazine/BookMagazineConstants";

const initialState = {
  isLoading: false,
  BookMagazinePageData: {},
  errorMessage: "",
};

export const BookMagazineReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOK_MAGAZINE_PAGE_LOADER:
      return { ...state, isLoading: true };

    case BOOK_MAGAZINE_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        BookMagazinePageData: action.payload,
        errorMessage: "",
      };

    case BOOK_MAGAZINE_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case BOOK_MAGAZINE_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
