import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
// import { NavbarDataList } from "../../store/actions/Navbar/NavbarData";
import styles from "./navigationBarB.module.scss";

function NavigationbarB() {
  const [navbarMenu, setNavbarMenu] = useState(false);
  const [radius, setRadius] = useState(false);

  // const { isLoading, NavbarData, errorMessage } = useSelector(
  //   (state) => state?.NavbarReducer
  // );
  // const dispatch = useDispatch();

  const ref = useRef();

  const location = useLocation();
  useEffect(() => {
    setNavbarMenu(false);
  }, [location]);

  // useEffect(() => {
  //   dispatch(NavbarDataList());
  // }, [dispatch]);

  const handleClick = (event) => {
    if (!ref?.current?.contains(event?.target)) {
      setNavbarMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("event", handleClick);
    };
  }, []);

  useEffect(() => {
    if (!navbarMenu) {
      setTimeout(() => {
        setRadius(navbarMenu);
      }, 800);
    } else {
      setRadius(navbarMenu);
    }
  }, [navbarMenu]);

  return (
    <>
      <div ref={ref} className={`${styles.body}`}>
        <div
          className={`${styles.menu} ${!!navbarMenu ? styles.menuOpen : ""}`}
        >
          <div
            className={`${styles.menuTop} ${
              !!radius ? styles.removeBottomRadius : ""
            } d-flex align-items-center justify-content-between`}
            style={{ backgroundColor: !!navbarMenu ? "#00b75f" : "#00b75f" }}
          >
            <Link to="/">
              <img
                loading="lazy"
                src={require("../../assets/images/designem-logo.png")}
                alt="logo"
                className={`${styles.menuTitle} h-100`}
              />
            </Link>

            <div
              className={`d-flex flex-row align-items-center ${styles.menuNavTop}`}
            >
              <Link className={`${styles.lineLink}`} to="/">
                Home
              </Link>

              <p
                className={`${styles.lineLink} my-0`}
                onClick={() => setNavbarMenu(true)}
              >
                Services
              </p>

              <Link className={`${styles.lineLink}`} to="portfolio">
                Portfolio
              </Link>

              <Link className={`${styles.lineLink}`} to="blog">
                Blog
              </Link>

              <Link className={`${styles.lineLink}`} to="#">
                Pricing
              </Link>

              <p
                className={`${styles.lineLink} my-0`}
                onClick={() => setNavbarMenu(true)}
              >
                About
              </p>

              {/**
              {!!NavbarData?.top_content &&
                NavbarData?.top_content?.map((value, index) =>
                  !!value?.to ? (
                    <Link
                      key={index}
                      className={`${styles.lineLink}`}
                      to={value?.to}
                    >
                      {value?.text}
                    </Link>
                  ) : (
                    <p
                      key={index}
                      className={`my-0 ${styles.lineLink}`}
                      onClick={() => setNavbarMenu(true)}
                    >
                      {value?.text}
                    </p>
                  )
                )}
              */}
            </div>

            <div></div>
          </div>

          <div
            className={`${styles.menuContentWrap} `}
            onClick={() => setNavbarMenu(false)}
          >
            <div
              className={`${styles.menuContent} d-flex justify-content-center`}
              style={{
                transform: `translate(0px, ${!!navbarMenu ? "0%" : "-100%"})`,
                backgroundColor: !!navbarMenu ? "#00b75f" : "#00b75f",
              }}
            >
              <div className={`row w-100 justify-content-between`}>
                <div
                  className={`col-md-3 d-flex align-items-end ${styles.menuTag}`}
                >
                  <h3 className={`${styles.menuTagline}`}>Start'em</h3>
                </div>

                <div className="col-10 col-md-6">
                  <div className="w-100 row align-items-start justify-content-start">
                    <div
                      className={`${styles.column} ${styles.columnHider} col-12 col-md-6 d-flex flex-column`}
                    >
                      {/**
                      {!!NavbarData?.top_content &&
                        NavbarData?.top_content?.map(
                          (value, index) =>
                            !!value?.to && (
                              <Link
                                key={index}
                                className={`${styles.header}`}
                                to={value?.to}
                              >
                                {value?.text}
                              </Link>
                            )
                        )}
                      */}
                    </div>

                    <div
                      className={`${styles.column} col-12 col-md-6 d-flex flex-column`}
                    >
                      <Link to={"book-magazine"} className={`${styles.header}`}>
                        Book and Magazine
                      </Link>

                      <Link
                        className={`${styles.link}`}
                        to={"book-magazine/book-cover"}
                      >
                        Book Cover
                      </Link>

                      <Link
                        className={`${styles.link}`}
                        to={"book-magazine/ebook-cover"}
                      >
                        Ebook Cover
                      </Link>

                      <Link
                        className={`${styles.link}`}
                        to={"book-magazine/magazine-designing"}
                      >
                        Magazine Designing
                      </Link>
                    </div>

                    <div
                      className={`${styles.column} col-12 col-md-6 d-flex flex-column`}
                    >
                      <Link to={"web-app"} className={`${styles.header}`}>
                        Web App Designing
                      </Link>

                      <Link className={`${styles.link}`} to={"web-app/landing"}>
                        Landing
                      </Link>

                      <Link
                        className={`${styles.link}`}
                        to={"web-app/app-design"}
                      >
                        App Designing
                      </Link>

                      <Link className={`${styles.link}`} to={"web-app/website"}>
                        Website
                      </Link>
                    </div>

                    <div
                      className={`${styles.column} col-12 col-md-6 d-flex flex-column`}
                    >
                      <Link
                        to={"promotional-products"}
                        className={`${styles.header}`}
                      >
                        Promotional Products
                      </Link>
                    </div>

                    <div
                      className={`${styles.column} col-12 col-md-6 d-flex flex-column`}
                    >
                      <Link to={"social-media"} className={`${styles.header}`}>
                        Social Media Design
                      </Link>
                    </div>

                    <div
                      className={`${styles.column} col-12 col-md-6 d-flex flex-column`}
                    >
                      <Link
                        to={"packaging-label"}
                        className={`${styles.header}`}
                      >
                        Packaging and Label
                      </Link>
                      <Link
                        to={"packaging-label/cosmetics"}
                        className={`${styles.link}`}
                      >
                        Cosmetics
                      </Link>
                      <Link
                        to={"packaging-label/food-packaging"}
                        className={`${styles.link}`}
                      >
                        Food packaging
                      </Link>
                      <Link
                        to={"packaging-label/product-packaging"}
                        className={`${styles.link}`}
                      >
                        Product packaging
                      </Link>
                      <Link
                        to={"packaging-label/retail-packaging"}
                        className={`${styles.link}`}
                      >
                        Retail packaging
                      </Link>
                    </div>

                    <div
                      className={`${styles.column} col-12 col-md-6 d-flex flex-column`}
                    >
                      <Link
                        to={"clothing-merchandise"}
                        className={`${styles.header}`}
                      >
                        CLothing and Merchandise
                      </Link>
                      <Link
                        to={"clothing-merchandise/apparel"}
                        className={`${styles.link}`}
                      >
                        Apperal Designing
                      </Link>
                      <Link
                        to={"clothing-merchandise/bag-tote"}
                        className={`${styles.link}`}
                      >
                        Bag Tote Designing
                      </Link>
                      <Link
                        to={"clothing-merchandise/jerseys-hoodies"}
                        className={`${styles.link}`}
                      >
                        Jerseys and Hoodies Designing
                      </Link>

                      <Link
                        to={"clothing-merchandise/shopping-bag"}
                        className={`${styles.link}`}
                      >
                        Shopping Bag Designing
                      </Link>
                      <Link
                        to={"clothing-merchandise/tshirt"}
                        className={`${styles.link}`}
                      >
                        Tshirt Designing
                      </Link>
                    </div>

                    <div
                      className={`${styles.column} col-12 col-md-6 d-flex flex-column`}
                    >
                      <Link to={"logo-identity"} className={`${styles.header}`}>
                        Logo Identity
                      </Link>
                      <Link
                        to={"logo-identity/brand"}
                        className={`${styles.link}`}
                      >
                        Brand Identity
                      </Link>
                      <Link
                        to={"logo-identity/logo"}
                        className={`${styles.link}`}
                      >
                        Logo Designing
                      </Link>
                    </div>

                    <div
                      className={`${styles.column} col-12 col-md-6 d-flex flex-column`}
                    >
                      <h4 className={`${styles.header}`}>About Us</h4>

                      <Link className={`${styles.link}`} to="team">
                        Out Team
                      </Link>

                      <Link className={`${styles.link}`} to="contact">
                        Contact
                      </Link>
                    </div>

                    {/**
                    {!!NavbarData?.links &&
                      NavbarData?.links?.map((value, index) => (
                        <div
                          key={index}
                          className={`${styles.column} col-12 col-md-6 d-flex flex-column`}
                        >
                          <h4 className={`${styles.header}`}>
                            {value?.header}
                          </h4>
                          {!!value?.pages &&
                            value?.pages?.map((value2, index2) => (
                              <Link
                                key={index2}
                                className={`${styles.link}`}
                                to={value2?.to}
                              >
                                {value2?.name}
                              </Link>
                            ))}
                        </div>
                      ))}
                    */}

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>

                <div className="col-2 col-md-2">
                  <div
                    className={`${styles.menuBack} ${styles.unbutton}`}
                    onClick={() => setNavbarMenu(false)}
                  >
                    <svg
                      width="10"
                      height="182"
                      viewBox="0 0 10 121"
                      preserveAspectRatio="xMidYMin meet"
                      style={{ fill: "white" }}
                    >
                      <path d="M5 0 .5 9h9L5 0Zm.5 120.5V8h-1v113h1v-.5Z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavigationbarB;
