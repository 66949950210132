import React from "react";
import { Outlet } from "react-router-dom";
import ScrollProgressBar from "./modules/scrollProgressBar/ScrollProgressBar";
import Footer from "./modules/footer/Footer";
import FooterBottom from "./modules/footerBottom/FooterBottom";
import NavigationbarB from "./modules/navbar/NavigationbarB";

function Body() {
  return (
    <>
      <NavigationbarB />
      <ScrollProgressBar />
      <Outlet />
      <Footer />
      <FooterBottom />
    </>
  );
}

export default Body;
