import React, { useEffect, useRef, useState } from "react";

function ScrollProgressBar() {
  const [scrollTop, setScrollTop] = useState(0);
  const circle = useRef();

  useEffect(() => {
    let len = circle.current.getTotalLength();
    circle.current.style.strokeDasharray = len + " " + len;
    circle.current.style.strokeDashoffset = len;
    circle.current.style.transition = circle.current.style.WebkitTransition =
      "stroke-dashoffset 10ms linear";

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {
    let documentHeight = document.body.scrollHeight;
    let windowHeight = window.innerHeight;
    let len = circle.current.getTotalLength();

    circle.current.style.strokeDashoffset =
      len - (scrollTop * len) / (documentHeight - windowHeight);
  }, [scrollTop]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {/*scroll-progress & goto-top */}
      <div
        className={`scroll-progress cursor-pointer ${
          scrollTop > 50 ? "active-progress" : ""
        }`}
        onClick={() => scrollToTop()}
      >
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path ref={circle} d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </>
  );
}

export default ScrollProgressBar;
