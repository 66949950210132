import {
  LOGO_DESIGNING_PAGE_LOADER,
  LOGO_DESIGNING_PAGE_SUCCESS,
  LOGO_DESIGNING_PAGE_FAILURE,
  LOGO_DESIGNING_PAGE_RESET,
} from "../../constants/Logo Identity/LogoDesigningConstants";

const initialState = {
  isLoading: false,
  LogoDesigningPageData: {},
  errorMessage: "",
};

export const LogoDesigningReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGO_DESIGNING_PAGE_LOADER:
      return { ...state, isLoading: true };

    case LOGO_DESIGNING_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        LogoDesigningPageData: action.payload,
        errorMessage: "",
      };

    case LOGO_DESIGNING_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case LOGO_DESIGNING_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
