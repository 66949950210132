import React, { useEffect, useState } from "react";

function PageHeader({ heading, text }) {
  const [visible, setVisible] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setVisible("visible");
    }, 500);
  }, []);

  return (
    <>
      <section className="page-header">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="my-0 my-lg-5">
                <p className={`mb-3 font-weight-500 has-anim ${visible} fade`}>
                  {text}
                </p>
                {heading}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PageHeader;
