import { Directus } from "@directus/sdk";

export const get = async (content, query) => {
  const directusConfig = new Directus(process.env.REACT_APP_DIRECTUS_URL);

  const items = await directusConfig.items(content).readByQuery(query);
  //   {
  //     limit: -1,
  //   }
  return items;
};
