import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageHeader from "../../modules/pageHeader/PageHeader";

function Portfolio() {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setVisible("visible");
    }, 500);

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {}, [scrollTop]);

  return (
    <>
      {/*<!-- start of page-header -->*/}
      <PageHeader
        heading={
          <h2
            className={`text-white font-weight-500 has-anim ${visible} fade anim-delay-1`}
          >
            Grid 4 Column<span className="text-primary">.</span>
          </h2>
        }
        text={"Our Portfolio"}
      />

      {/*<!-- end of page-header -->*/}

      {/*<!-- start of portfolio -->*/}
      <section className="section bg-white">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className={`mb-4 pb-3 has-anim ${visible}`}>
                <div className="card portfolio-card card-overlay card-hover-zoom">
                  <Link
                    className="card-img overflow-hidden rounded-0"
                    to="single-portfolio"
                  >
                    <img
                      loading="lazy"
                      className="img-fluid"
                      src={require("../../assets/images/portfolio/grid/07.jpg")}
                      alt="Portfolio Thumbnail"
                    />
                  </Link>
                </div>
                <div className="block mt-4">
                  <h3 className="h4 mb-1">
                    <Link className="text-dark" to="single-portfolio">
                      Coffee
                    </Link>
                  </h3>
                  <p className="font-weight-500 mb-0">Food</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className={`mb-4 pb-3 has-anim ${visible} anim-delay-1`}>
                <div className="card portfolio-card card-overlay card-hover-zoom">
                  <Link
                    className="card-img overflow-hidden rounded-0"
                    to="single-portfolio"
                  >
                    <img
                      loading="lazy"
                      className="img-fluid"
                      src={require("../../assets/images/portfolio/grid/08.jpg")}
                      alt="Portfolio Thumbnail"
                    />
                  </Link>
                </div>
                <div className="block mt-4">
                  <h3 className="h4 mb-1">
                    <Link className="text-dark" to="single-portfolio">
                      Cinnamon
                    </Link>
                  </h3>
                  <p className="font-weight-500 mb-0">Food</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className={`mb-4 pb-3 has-anim ${visible} anim-delay-2`}>
                <div className="card portfolio-card card-overlay card-hover-zoom">
                  <Link
                    className="card-img overflow-hidden rounded-0"
                    to="single-portfolio"
                  >
                    <img
                      loading="lazy"
                      className="img-fluid"
                      src={require("../../assets/images/portfolio/grid/01.jpg")}
                      alt="Portfolio Thumbnail"
                    />
                  </Link>
                </div>
                <div className="block mt-4">
                  <h3 className="h4 mb-1">
                    <Link className="text-dark" to="single-portfolio">
                      Observer
                    </Link>
                  </h3>
                  <p className="font-weight-500 mb-0">Nature</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className={`mb-4 pb-3 has-anim ${visible} anim-delay-3`}>
                <div className="card portfolio-card card-overlay card-hover-zoom">
                  <Link
                    className="card-img overflow-hidden rounded-0"
                    to="single-portfolio"
                  >
                    <img
                      loading="lazy"
                      className="img-fluid"
                      src={require("../../assets/images/portfolio/grid/02.jpg")}
                      alt="Portfolio Thumbnail"
                    />
                  </Link>
                </div>
                <div className="block mt-4">
                  <h3 className="h4 mb-1">
                    <Link className="text-dark" to="single-portfolio">
                      Phoenix Plant
                    </Link>
                  </h3>
                  <p className="font-weight-500 mb-0">Plant</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className={`mb-4 pb-3 has-anim ${visible}`}>
                <div className="card portfolio-card card-overlay card-hover-zoom">
                  <Link
                    className="card-img overflow-hidden rounded-0"
                    to="single-portfolio"
                  >
                    <img
                      loading="lazy"
                      className="img-fluid"
                      src={require("../../assets/images/portfolio/grid/03.jpg")}
                      alt="Portfolio Thumbnail"
                    />
                  </Link>
                </div>
                <div className="block mt-4">
                  <h3 className="h4 mb-1">
                    <Link className="text-dark" to="single-portfolio">
                      Pointing
                    </Link>
                  </h3>
                  <p className="font-weight-500 mb-0">Sign</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className={`mb-4 pb-3 has-anim ${visible} anim-delay-1`}>
                <div className="card portfolio-card card-overlay card-hover-zoom">
                  <Link
                    className="card-img overflow-hidden rounded-0"
                    to="single-portfolio"
                  >
                    <img
                      loading="lazy"
                      className="img-fluid"
                      src={require("../../assets/images/portfolio/grid/04.jpg")}
                      alt="Portfolio Thumbnail"
                    />
                  </Link>
                </div>
                <div className="block mt-4">
                  <h3 className="h4 mb-1">
                    <Link className="text-dark" to="single-portfolio">
                      Bright Stairs
                    </Link>
                  </h3>
                  <p className="font-weight-500 mb-0">Construction</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className={`mb-4 pb-3 has-anim ${visible} anim-delay-2`}>
                <div className="card portfolio-card card-overlay card-hover-zoom">
                  <Link
                    className="card-img overflow-hidden rounded-0"
                    to="single-portfolio"
                  >
                    <img
                      loading="lazy"
                      className="img-fluid"
                      src={require("../../assets/images/portfolio/grid/05.jpg")}
                      alt="Portfolio Thumbnail"
                    />
                  </Link>
                </div>
                <div className="block mt-4">
                  <h3 className="h4 mb-1">
                    <Link className="text-dark" to="single-portfolio">
                      Patternify
                    </Link>
                  </h3>
                  <p className="font-weight-500 mb-0">Construction</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className={`mb-4 pb-3 has-anim ${visible} anim-delay-3`}>
                <div className="card portfolio-card card-overlay card-hover-zoom">
                  <Link
                    className="card-img overflow-hidden rounded-0"
                    to="single-portfolio"
                  >
                    <img
                      loading="lazy"
                      className="img-fluid"
                      src={require("../../assets/images/portfolio/grid/06.jpg")}
                      alt="Portfolio Thumbnail"
                    />
                  </Link>
                </div>
                <div className="block mt-4">
                  <h3 className="h4 mb-1">
                    <Link className="text-dark" to="single-portfolio">
                      Yellow Patio
                    </Link>
                  </h3>
                  <p className="font-weight-500 mb-0">Umbrella</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className={`col-12 text-center has-anim ${visible}`}>
              <Link className="btn btn-black" data-text="View More">
                <span>View More</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- end of portfolio -->*/}
    </>
  );
}

export default Portfolio;
