import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function PortfolioSingle() {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setVisible("visible");
    }, 500);

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {}, [scrollTop]);
  return (
    <>
      {/*<!-- start of page-header -->*/}
      <section className="page-header pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="mt-0 mt-lg-4">
                <p className={`mb-3 font-weight-500 has-anim ${visible} fade`}>
                  Case Study
                </p>
                <h2
                  className={`text-white font-weight-500 has-anim ${visible} fade anim-delay-1`}
                >
                  The cleanest website by Cyprees and team
                  <span className="text-primary">.</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- end of page-header -->*/}

      {/*<!-- end of portfolio-details -->*/}
      <section className="section pb-0">
        <div className="container">
          <div className="row">
            <div className={`col-12 mb-n5 has-anim ${visible} anim-delay-2`}>
              <img
                loading="lazy"
                className="img-fluid"
                src={require("../../assets/images/portfolio/portfolio-details/01.jpg")}
                alt="portfolio thumb"
              />
            </div>
          </div>
        </div>

        <div className="section bg-white">
          <div className="container">
            <div className="row pt-5">
              <div className="col-lg-4 mt-1">
                <div className={`mb-5 has-anim ${visible} fade`}>
                  <h3 className="h5 mb-2 text-dark">You &amp; Tonik</h3>
                  <p className="mb-3">
                    A digital product studio that tickles users in all the right
                    places making your business thrive.
                  </p>
                  <Link className="line-link text-dark font-weight-500">
                    Visit website{" "}
                    <svg
                      className="arrow-up-right"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <line x1="7" y1="17" x2="17" y2="7"></line>
                      <polyline points="7 7 17 7 17 17"></polyline>
                    </svg>
                  </Link>
                </div>

                <div className={`mb-5 has-anim ${visible} fade anim-delay-1`}>
                  <h3 className="h5 mb-2 text-dark">Headquarters</h3>
                  <p>London, UK</p>
                </div>

                <div className={`mb-5 has-anim ${visible} fade anim-delay-2`}>
                  <h3 className="h5 mb-2 text-dark">Company size</h3>
                  <p>51 — 200 employees</p>
                </div>

                <div className={`mb-5 has-anim ${visible} fade anim-delay-3`}>
                  <h3 className="h5 mb-2 text-dark">Services we provided</h3>
                  <ul className="list-unstyled">
                    <li>Visual identity</li>
                    <li>CX and Content strategy</li>
                    <li>Website design</li>
                    <li>Design system</li>
                    <li>CMS integration</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="pl-0 pl-lg-5">
                  <div className="mb-5">
                    <h3 className="text-dark h3 mb-3">The client</h3>
                    <p className="lead">
                      A digital product studio that tickles users in all the
                      right places making your business thrive. Sequi omnis
                      nobis distinctio assumenda nesciunt eveniet recusandae
                      nihil aperiam iure, dolores laboriosam dolorum deserunt
                      quisquam!
                    </p>
                  </div>

                  <img
                    loading="lazy"
                    className="img-fluid mb-5"
                    src={require("../../assets/images/portfolio/portfolio-details/02.gif")}
                    alt="portfolio thumb"
                    height="450"
                  />

                  <div className="mb-5">
                    <h3 className="text-dark h3 mb-3">Problem</h3>
                    <p className="lead">
                      Quia nisi necessitatibus harum maiores explicabo,
                      asperiores ex deleniti. Molestiae perspiciatis quidem
                      dignissimos reiciendis atque illo, odit necessitatibus
                      officiis commodi suscipit maiores asperiores magni quam?
                      Sed, labore!
                    </p>
                  </div>

                  <img
                    loading="lazy"
                    className="img-fluid mb-5"
                    src={require("../../assets/images/portfolio/portfolio-details/03.gif")}
                    alt="portfolio thumb"
                    height="450"
                  />

                  <div className="mb-5">
                    <h3 className="text-dark h3 mb-3">Solution</h3>
                    <p className="lead">
                      Laudantium fugiat sapiente illo tempora amet obcaecati
                      adipisci at, asperiores fuga nesciunt totam enim quia
                      nihil dolores delectus cumque saepe aspernatur dicta
                      repellat animi odit numquam facere.
                    </p>
                  </div>

                  <img
                    loading="lazy"
                    className="img-fluid mb-5"
                    src={require("../../assets/images/portfolio/portfolio-details/04.jpg")}
                    alt="portfolio thumb"
                    height="450"
                  />

                  <div className="mb-5">
                    <p className="lead">
                      Laudantium fugiat sapiente illo tempora amet obcaecati
                      adipisci at, asperiores fuga nesciunt totam enim quia
                      nihil dolores delectus cumque saepe aspernatur dicta
                      repellat animi odit numquam facere.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={require("../../assets/images/portfolio/portfolio-details/05.jpg")}
                  alt="portfolio thumb"
                  height="450"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="section pt-0 bg-white">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-12 mb-5">
                <div className="section border-top pb-0"></div>
                <h3
                  className={`h2 text-dark font-weight-500 has-anim ${visible} fade`}
                >
                  What’s next?
                </h3>
              </div>
              <div
                className={`col-lg-5 col-md-6 mb-5 mb-md-0 has-anim ${visible} fade`}
              >
                <div className="card portfolio-card card-overlay card-hover-zoom">
                  <span className="card-img">
                    <img
                      className="img-fluid"
                      src={require("../../assets/images/portfolio/masonry/05.jpg")}
                      alt="Portfolio Thumbnail"
                    />
                    <span
                      className="img-overlay"
                      style={{ backgroundColor: "#B10101" }}
                    ></span>
                  </span>
                  <div className="card-img-overlay">
                    <div className="block overflow-hidden">
                      <span className="card-subtitle mb-2">
                        <Link>Branding</Link>
                      </span>
                      <h3 className="card-title mb-0">
                        <Link>Cocacola</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`col-lg-5 col-md-6 has-anim ${visible} fade anim-delay-1`}
              >
                <div className="card portfolio-card card-overlay card-hover-zoom">
                  <span className="card-img">
                    <img
                      className="img-fluid"
                      src={require("../../assets/images/portfolio/masonry/02.jpg")}
                      alt="Portfolio Thumbnail"
                    />
                    <span
                      className="img-overlay"
                      style={{ backgroundColor: "#0E268A" }}
                    ></span>
                  </span>
                  <div className="card-img-overlay">
                    <div className="block overflow-hidden">
                      <span className="card-subtitle mb-2">
                        <Link>Design</Link>
                      </span>
                      <h3 className="card-title mb-0">
                        <Link>F.A M.T</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- end of portfolio-details -->*/}
    </>
  );
}

export default PortfolioSingle;
