import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageHeader from "../../modules/pageHeader/PageHeader";

function Team() {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setVisible("visible");
    }, 500);

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {}, [scrollTop]);

  return (
    <>
      <PageHeader
        heading={
          <h2
            className={`text-white font-weight-500 has-anim ${visible} fade anim-delay-1`}
          >
            Powerful Team<span className="text-primary">.</span>
          </h2>
        }
        text={"Our Awesome"}
      />

      {/*<!-- start of teams -->*/}
      <section className="section bg-white">
        <div className="container">
          <div className="row row-team">
            <div className="col-xl-3 col-lg-4 col-6">
              <div className={`team-item has-anim ${visible} fade`}>
                <div className="block overflow-hidden position-relative">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={require("../../assets/images/team/03.jpg")}
                    alt="Team_Image"
                  />
                  <div className="social-profile d-flex align-items-end justify-content-center">
                    <ul className="list-unstyled list-inline mb-4 pb-2">
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-facebook-f"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-twitter"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-linkedin-in"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-github"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Riddo Graham</h4>
                  <p className="h6 font-weight-400 mt-1">Ower &amp; Founder</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className={`team-item has-anim ${visible} fade anim-delay-1`}>
                <div className="block overflow-hidden position-relative">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={require("../../assets/images/team/01.jpg")}
                    alt="Team_Image"
                  />
                  <div className="social-profile d-flex align-items-end justify-content-center">
                    <ul className="list-unstyled list-inline mb-4 pb-2">
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-facebook-f"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-twitter"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-github"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Luis Fransisco</h4>
                  <p className="h6 font-weight-400 mt-1">Chief Product Maneger</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className={`team-item has-anim ${visible} fade anim-delay-2`}>
                <div className="block overflow-hidden position-relative">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={require("../../assets/images/team/02.jpg")}
                    alt="Team_Image"
                  />
                  <div className="social-profile d-flex align-items-end justify-content-center">
                    <ul className="list-unstyled list-inline mb-4 pb-2">
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-facebook-f"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-twitter"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-linkedin-in"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-github"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Hena Linton</h4>
                  <p className="h6 font-weight-400 mt-1">Product Designer</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className={`team-item has-anim ${visible} fade anim-delay-3`}>
                <div className="block overflow-hidden position-relative">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={require("../../assets/images/team/04.jpg")}
                    alt="Team_Image"
                  />
                  <div className="social-profile d-flex align-items-end justify-content-center">
                    <ul className="list-unstyled list-inline mb-4 pb-2">
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-facebook-f"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-twitter"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-linkedin-in"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-github"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Joshuwa Delgado</h4>
                  <p className="h6 font-weight-400 mt-1">Web Developer</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className={`team-item has-anim ${visible} fade`}>
                <div className="block overflow-hidden position-relative">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={require("../../assets/images/team/05.jpg")}
                    alt="Team_Image"
                  />
                  <div className="social-profile d-flex align-items-end justify-content-center">
                    <ul className="list-unstyled list-inline mb-4 pb-2">
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-facebook-f"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-twitter"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-github"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Rubaik Jakson</h4>
                  <p className="h6 font-weight-400 mt-1">Markating Head</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className={`team-item has-anim ${visible} fade anim-delay-2`}>
                <div className="block overflow-hidden position-relative">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={require("../../assets/images/team/06.jpg")}
                    alt="Team_Image"
                  />
                  <div className="social-profile d-flex align-items-end justify-content-center">
                    <ul className="list-unstyled list-inline mb-4 pb-2">
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-facebook-f"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-github"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Alentrona Shimla</h4>
                  <p className="h6 font-weight-400 mt-1">UI Artist</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className={`team-item has-anim ${visible} fade anim-delay-3`}>
                <div className="block overflow-hidden position-relative">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={require("../../assets/images/team/07.jpg")}
                    alt="Team_Image"
                  />
                  <div className="social-profile d-flex align-items-end justify-content-center">
                    <ul className="list-unstyled list-inline mb-4 pb-2">
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-facebook-f"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-github"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Jason Moyer</h4>
                  <p className="h6 font-weight-400 mt-1">Business Consultant</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-6">
              <div className={`team-item has-anim ${visible} fade anim-delay-1`}>
                <div className="block overflow-hidden position-relative">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={require("../../assets/images/team/08.jpg")}
                    alt="Team_Image"
                  />
                  <div className="social-profile d-flex align-items-end justify-content-center">
                    <ul className="list-unstyled list-inline mb-4 pb-2">
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-facebook-f"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link>
                          <i className="lab la-github"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">Luke Jones</h4>
                  <p className="h6 font-weight-400 mt-1">Graphic Designer</p>
                </div>
              </div>
            </div>

            <div
              className={`col-12 mt-5 pt-4 text-center has-anim ${visible} fade`}
            >
              <Link className="btn btn-black" data-text="See Open Positions">
                <span>See Open Positions</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- end of teams -->*/}

      {/*<!-- start of open-position -->*/}
      <section
        id="open-positions"
        className="section"
        style={{ borderBottom: "1px solid #151829" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className={`section-title text-center has-anim ${visible} fade`}>
                <p className="mb-2">Apply On Our</p>
                <h2 className="title text-white">Open Positions</h2>
              </div>

              <div
                className={`row justify-content-center pt-3 has-anim ${visible} fade`}
              >
                <div className="col-md-4 mb-4 mb-md-0">
                  <h3 className="text-white font-weight-500">Customer Support</h3>
                </div>
                <div className="col-lg-7 col-md-8">
                  <ul className="open-positions-list pl-0 pl-md-5">
                    <li>
                      <h4>
                        <Link className="text-light font-weight-500 d-block mb-2">
                          Customer Support Specialist - Billing, (NZ/Australia)
                        </Link>
                      </h4>
                      <span>New Zealand or Australia Remote</span>
                    </li>
                    <li>
                      <h4>
                        <Link className="text-light font-weight-500 d-block mb-2">
                          Manager, Customer Support (UK/Ireland)
                        </Link>
                      </h4>
                      <span>UK/Ireland Remote</span>
                    </li>
                    <li>
                      <h4>
                        <Link className="text-light font-weight-500 d-block mb-2">
                          Technical Customer Support Specialist, (NZ/Australia)
                        </Link>
                      </h4>
                      <span>New Zealand or Australia Remote</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className={`row justify-content-center pt-3 mt-5 has-anim ${visible} fade`}
              >
                <div className="col-md-4 mb-4 mb-md-0">
                  <h3 className="text-white font-weight-500">Marketing</h3>
                </div>
                <div className="col-lg-7 col-md-8">
                  <ul className="open-positions-list pl-0 pl-md-5">
                    <li>
                      <h4>
                        <Link className="text-light font-weight-500 d-block mb-2">
                          Product Marketing Lead
                        </Link>
                      </h4>
                      <span>San Francisco HQ or US Remote</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className={`row justify-content-center pt-3 mt-5 has-anim ${visible} fade`}
              >
                <div className="col-md-4 mb-4 mb-md-0">
                  <h3 className="text-white font-weight-500">Design</h3>
                </div>
                <div className="col-lg-7 col-md-8">
                  <ul className="open-positions-list pl-0 pl-md-5">
                    <li>
                      <h4>
                        <Link className="text-light font-weight-500 d-block mb-2">
                          Senior Product Designer, Growth
                        </Link>
                      </h4>
                      <span>Remote</span>
                    </li>
                    <li>
                      <h4>
                        <Link className="text-light font-weight-500 d-block mb-2">
                          Senior Product Designer, Marketplace
                        </Link>
                      </h4>
                      <span>US Remote</span>
                    </li>
                    <li>
                      <h4>
                        <Link className="text-light font-weight-500 d-block mb-2">
                          Senior Product Design Manager, Design Systems
                        </Link>
                      </h4>
                      <span>US remote</span>
                    </li>
                    <li>
                      <h4>
                        <Link className="text-light font-weight-500 d-block mb-2">
                          Senior UI Designer, Design Systems
                        </Link>
                      </h4>
                      <span>US Remote</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- end of open-position -->*/}
    </>
  );
}

export default Team;
