import {
  APPAREL_DESIGNING_PAGE_LOADER,
  APPAREL_DESIGNING_PAGE_SUCCESS,
  APPAREL_DESIGNING_PAGE_FAILURE,
} from "../../constants/Clothing and Merchandise/ApparelDesigningConstants";
import { getPage } from "../../../API/Api";

const success = (payload) => ({
  type: APPAREL_DESIGNING_PAGE_SUCCESS,
  payload,
});

const failure = (error) => ({
  type: APPAREL_DESIGNING_PAGE_FAILURE,
  error,
});

export const ApparelDesigningData = () => (dispatch) => {
  dispatch({ type: APPAREL_DESIGNING_PAGE_LOADER });
  getPage("designem_page", {
    filter: {
      page_name: {
        _eq: "Apparel Designing",
      },
    },
    fields: "*.*.*.*.*.*.*",
  })
    .then((response) => {
      if (!!response?.data?.length) dispatch(success(response?.data[0]));
      else dispatch(failure("Something Went Wrong"));
    })
    .catch((err) => {
      dispatch(failure(err?.errors));
    });
};
