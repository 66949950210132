import React from "react";
import styles from "./action.module.scss";

function Action({ action, callToAction }) {
  return (
    <div className={styles.body}>
      <h2>{action?.header}</h2>
      <p className={styles.text}>{action?.text}</p>
      {!!action?.list && (
        <ul>
          {action?.list?.map((value, index) => (
            <li key={index}>{value?.text}</li>
          ))}
        </ul>
      )}
      {callToAction}
    </div>
  );
}

export default Action;
