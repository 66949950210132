import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Footer() {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setVisible("visible");
    }, 500);

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  return (
    <>
      {/*start of footer */}
      <footer className={`section-sm pb-4 has-anim ${visible}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h4 className="h3 font-weight-light text-white">
                  Have an idea, tell us about it!
                  <Link className="text-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <line x1="7" y1="17" x2="17" y2="7"></line>
                      <polyline points="7 7 17 7 17 17"></polyline>
                    </svg>
                  </Link>
                </h4>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 mb-4 mb-lg-0">
              <h4 className="font-weight-light mb-4">
                <Link className="line-link">contact@designem.com</Link>
              </h4>

              <p>
                Design amazing digital experiences that
                <br /> create more happy in the world.
              </p>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-4 col-6 ml-auto">
              <ul className="footer-link list-unstyled">
                <li>
                  <Link>About us</Link>
                </li>
                <li>
                  <Link to="services">Services</Link>
                </li>
                <li>
                  <Link to="portfolio">Portfolio</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-4 col-6">
              <ul className="footer-link list-unstyled">
                <li>
                  <Link to="blogs">Our blog</Link>
                </li>
                <li>
                  <Link to="team">Our team</Link>
                </li>
                <li>
                  <Link>Pricing plans</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
              <ul className="footer-link list-unstyled">
                <li>
                  <Link to="contact">Contact</Link>
                </li>
                <li>
                  <Link>Privacy &amp; policy</Link>
                </li>
                <li>
                  <Link>FAQ</Link>
                </li>
              </ul>
            </div>
            <div className="col-12">
              <ul className="footer-link list-unstyled list-inline mt-4 mt-md-5">
                <li className="list-inline-item">
                  <Link>Facebook</Link>
                </li>
                <li className="list-inline-item">
                  <Link>Twitter</Link>
                </li>
                <li className="list-inline-item">
                  <Link>Instagram</Link>
                </li>
                <li className="list-inline-item">
                  <Link>Linkedin</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/*end of footer */}
    </>
  );
}

export default Footer;
