import React from "react";
import styles from "./attention.module.scss";

function Attention({ attention, callToAction, position }) {
  return (
    <div
      className={`row ${position === "left" ? "flex-row-reverse" : ""} mx-0`}
      style={{ marginBottom: "6vw" }}
    >
      <div
        className={`${styles.body} col-7 d-flex flex-column justify-content-center`}
      >
        <h2>{attention?.header}</h2>
        {!!attention?.questions && (
          <>
            <ul>
              {attention?.questions?.map((value, index) => (
                <li key={index}>{value?.Q}</li>
              ))}
            </ul>
          </>
        )}
        <p className={styles.text}>{attention?.text}</p>
      </div>
      <div
        className={`col-5 d-flex ${
          position === "left" ? "justify-content-start" : "justify-content-end"
        }`}
      >
        <img
          className="w-75"
          src={
            !!attention?.img
              ? process.env.REACT_APP_CLOUDFLARE_URL + attention?.img
              : ""
          }
          alt="pic"
        />
      </div>
      <div className="col-12">{callToAction}</div>
    </div>
  );
}

export default Attention;
