import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import Attention from "../../../modules/attention/Attention";
import Banner from "../../../modules/banner/Banner";
import Desire from "../../../modules/desire/Desire";
import Interest from "../../../modules/interest/Interest";
import Introduction from "../../../modules/introduction/Introduction";
import PageHeader from "../../../modules/pageHeader/PageHeader";
import Template1 from "../../../modules/template/Template1";
import Template2 from "../../../modules/template/Template2";
import Template3 from "../../../modules/template/Template3";
import Template4 from "../../../modules/template/Template4";
import { ProductPackagingData } from "../../../store/actions/Packaging and Label/ProductPackagingData";

function ProductPackaging() {
  const [scrollTop, setScrollTop] = useState(0);
  const [visible, setVisible] = useState("");

  const { isLoading, ProductPackagingPageData, errorMessage } = useSelector(
    (state) => state?.ProductPackagingReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ProductPackagingData());
  }, [dispatch]);

  useEffect(() => {
    if (!!ProductPackagingPageData?.faq) {
      setFaq([
        ...ProductPackagingPageData?.faq?.faqs?.map((value) => ({
          Q: value?.Q,
          A: value?.A,
          collapse: true,
        })),
      ]);
    }
  }, [ProductPackagingPageData]);

  const [faq, setFaq] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setVisible("visible");
    }, 500);

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = (e) => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  const unCollapse = (index) => {
    const temp = [...faq];
    temp[index].collapse = !temp[index]?.collapse;
    setFaq([...temp]);
  };

  return (
    <>
      <Helmet>
        <title>Product Packaging Design Services - Designem USA</title>
        <meta
          name="description"
          content="Boost your sales with our product packaging design services. Our team of expert designers creates eye-catching and functional packaging designs that meet the specific needs of your product and target audience."
        />
      </Helmet>

      {/*<!-- start of page-header -->*/}
      <PageHeader
        heading={
          <h2
            className={`text-white font-weight-500 has-anim ${visible} fade anim-delay-1`}
          >
            {ProductPackagingPageData?.header?.heading}
            <span className="text-primary">.</span>
          </h2>
        }
        text={ProductPackagingPageData?.header?.title}
      />

      {/*<!-- end of page-header -->*/}

      {/*<!-- start of services  -->*/}
      <section className="section bg-white">
        <div className="container">
          <div
            className={`row justify-content-center align-items-center has-anim ${visible} fade`}
          >
            <div className="col-12 position-relative">
              <div className="service-icon-lg">
                <svg
                  viewBox="0 0 16 16"
                  fill="#8bc34a"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 15V1a7 7 0 1 1 0 14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"
                  />
                </svg>
              </div>

              {!!ProductPackagingPageData?.banner?.show && (
                <Banner
                  position={"left"}
                  banner={ProductPackagingPageData?.banner}
                />
              )}

              {!!ProductPackagingPageData?.introduction?.show && (
                <Introduction
                  position={"right"}
                  introduction={ProductPackagingPageData?.introduction}
                  callToAction={
                    <Template1
                      content={
                        ProductPackagingPageData?.introduction?.callToAction
                      }
                    />
                  }
                />
              )}

              {!!ProductPackagingPageData?.attention?.show && (
                <Attention
                  position={"left"}
                  attention={ProductPackagingPageData?.attention}
                  callToAction={
                    <Template2
                      content={
                        ProductPackagingPageData?.attention?.callToAction
                      }
                    />
                  }
                />
              )}

              {!!ProductPackagingPageData?.interest?.show && (
                <Interest
                  position={"right"}
                  interest={ProductPackagingPageData?.interest}
                  callToAction={
                    <Template3
                      content={ProductPackagingPageData?.interest?.callToAction}
                    />
                  }
                />
              )}

              {!!ProductPackagingPageData?.desire?.show && (
                <Desire
                  position={"left"}
                  desire={ProductPackagingPageData?.desire}
                  callToAction={
                    <Template4
                      content={ProductPackagingPageData?.desire?.callToAction}
                    />
                  }
                />
              )}
            </div>

            <div className="col-lg-10 mt-5">
              <h5 className={`text-dark mb-2 has-anim ${visible} fade`}>
                {ProductPackagingPageData?.faq?.title}
              </h5>
              <div className="accordion">
                {!!faq?.length &&
                  faq?.map((value, index) => (
                    <div
                      key={index}
                      className={`card has-anim ${visible} fade`}
                    >
                      <div className="card-header border-bottom-0 p-0" id="q1">
                        <p
                          className="d-flex justify-content-between font-w-600 faqHeader"
                          onClick={() => unCollapse(index)}
                        >
                          {value?.Q}
                        </p>
                      </div>
                      <div
                        id="faq1"
                        className={`collapse ${!value?.collapse ? "show" : ""}`}
                        aria-labelledby="q1"
                      >
                        <div className="card-body content">
                          <p>{value?.A}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- end of services  -->*/}
    </>
  );
}

export default ProductPackaging;
