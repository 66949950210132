import React from "react";
import styles from "./Template1.module.scss";

function Template1({ content }) {
  return (
    <div
      className={`container-fluid ${styles.body} px-0 d-flex align-items-center flex-column`}
    >
      <h3>{content?.split("|")[0]}</h3>
      <p>{content?.split("|")[1]}</p>
    </div>
  );
}

export default Template1;
