import React from "react";
import styles from "./Template2.module.scss";
import pic from "../../assets/images/Book And Magazine/Group 484.png";
import textImg from "../../assets/images/Book And Magazine/Group 482.png";
import ArrowIcon from "../../assets/images/Book And Magazine/Icon.svg";

function Template2({ content }) {
  return (
    <div
      className={`container-fluid position-relative ${styles.body} px-0 d-flex align-items-center flex-column`}
    >
      <img className="w-100" src={pic} alt="img" />
      <div className={`position-absolute ${styles.box} d-flex`}>
        <div>
          <p>{content?.split("|")[0]}</p>
          <h3>{content?.split("|")[1]}</h3>
        </div>

        <div className="ml-5 position-relative">
          <img className="h-100" src={textImg} alt="icon1" />
          <img
            className={`position-absolute ${styles.icon}`}
            src={ArrowIcon}
            alt="icon2"
          />
        </div>
      </div>
    </div>
  );
}

export default Template2;
