import {
  CLOTHING_MERCHANDISE_PAGE_LOADER,
  CLOTHING_MERCHANDISE_PAGE_SUCCESS,
  CLOTHING_MERCHANDISE_PAGE_FAILURE,
  CLOTHING_MERCHANDISE_PAGE_RESET,
} from "../../constants/Clothing and Merchandise/ClothingMerchandiseConstants";

const initialState = {
  isLoading: false,
  ClothingMerchandisePageData: {},
  errorMessage: "",
};

export const ClothingMerchandiseReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOTHING_MERCHANDISE_PAGE_LOADER:
      return { ...state, isLoading: true };

    case CLOTHING_MERCHANDISE_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ClothingMerchandisePageData: action.payload,
        errorMessage: "",
      };

    case CLOTHING_MERCHANDISE_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case CLOTHING_MERCHANDISE_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
