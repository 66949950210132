import {
  COSMETICS_PAGE_LOADER,
  COSMETICS_PAGE_SUCCESS,
  COSMETICS_PAGE_FAILURE,
  COSMETICS_PAGE_RESET,
} from "../../constants/Packaging and Label/CosmeticsConstants";

const initialState = {
  isLoading: false,
  CosmeticsPageData: {},
  errorMessage: "",
};

export const CosmeticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case COSMETICS_PAGE_LOADER:
      return { ...state, isLoading: true };

    case COSMETICS_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        CosmeticsPageData: action.payload,
        errorMessage: "",
      };

    case COSMETICS_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case COSMETICS_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
