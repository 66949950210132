import {
  PROMOTIONAL_PRODUCTS_PAGE_LOADER,
  PROMOTIONAL_PRODUCTS_PAGE_SUCCESS,
  PROMOTIONAL_PRODUCTS_PAGE_FAILURE,
  PROMOTIONAL_PRODUCTS_PAGE_RESET,
} from "../../constants/Promotional Products/PromotionalProductsConstants";

const initialState = {
  isLoading: false,
  PromotionalProductsPageData: {},
  errorMessage: "",
};

export const PromotionalProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROMOTIONAL_PRODUCTS_PAGE_LOADER:
      return { ...state, isLoading: true };

    case PROMOTIONAL_PRODUCTS_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        PromotionalProductsPageData: action.payload,
        errorMessage: "",
      };

    case PROMOTIONAL_PRODUCTS_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case PROMOTIONAL_PRODUCTS_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
