import {
  SHOPPING_BAG_PAGE_LOADER,
  SHOPPING_BAG_PAGE_SUCCESS,
  SHOPPING_BAG_PAGE_FAILURE,
  SHOPPING_BAG_PAGE_RESET,
} from "../../constants/Clothing and Merchandise/ShoppingBagConstants";

const initialState = {
  isLoading: false,
  ShoppingBagPageData: {},
  errorMessage: "",
};

export const ShoppingBagReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOPPING_BAG_PAGE_LOADER:
      return { ...state, isLoading: true };

    case SHOPPING_BAG_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ShoppingBagPageData: action.payload,
        errorMessage: "",
      };

    case SHOPPING_BAG_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };

    case SHOPPING_BAG_PAGE_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
